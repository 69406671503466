import ImportImageDialogController from '../../components/import-image-dialog/import-image-dialog.controller';
import MimeTypes from '../../model/domain/mime-types';
'use strict';
export default class GooglePickerService {
    constructor($window, $q, environment, $mdDialog, $log) {
        'ngInject';
        this.$window = $window;
        this.$q = $q;
        this.$mdDialog = $mdDialog;
        this.$log = $log;
        this._environment = environment;

        // Class properties
        this.developerKey = this._environment.google.auth.apiKey;
        this.clientId = this._environment.google.auth.clientId;
        this.scope = [
            //'https://www.googleapis.com/auth/drive.readonly',
            'https://www.googleapis.com/auth/drive.file'  // Added read/write access scope
        ];
        this.pickerApiLoaded = false;
        this.oauthToken = null;
        this.authInstance = null;  // Store auth instance for later use


        // Listen for messages from the picker window
        this.$window.addEventListener('message', this.handleMessage.bind(this), false);
    }

    init(importResultCallback) {
        const deferred = this.$q.defer();
        this.loadPicker(deferred);
        this.importResultCallback = importResultCallback;
        return deferred.promise;
    }

    loadPicker(deferred) {
        // Load auth and picker APIs
        this.$window.gapi.load('auth2', { 'callback': () => this.onAuthApiLoad(deferred) });
        this.$window.gapi.load('picker', { 'callback': () => this.onPickerApiLoad() });
    }

    onAuthApiLoad(deferred) {
        try {
            this.authInstance = this.$window.gapi.auth2.getAuthInstance();
        } catch (e) {
            this.$log.error('Auth2 instance not found. Initializing auth2...');
        }

        if (!this.authInstance) {
            this.authInstance = this.$window.gapi.auth2.init({
                client_id: this.clientId,
                scope: this.scope.join(' '),
            });
        }

        this.authInstance.signIn({
            prompt: 'select_account',
            scope: this.scope.join(' ')
        }).then((user) => {
            const authResult = user.getAuthResponse();
            if (authResult && authResult.access_token) {
                this.oauthToken = authResult.access_token;
                this.createPicker(deferred);
                deferred.resolve(this.oauthToken);
            } else {
                this.$log.error('Authorization failed: No access token received.');
                deferred.reject('Authorization failed');
            }
        }).catch((error) => {
            this.$log.error('Error during sign-in:', error);
            deferred.reject(error);
        });
    }

    onPickerApiLoad() {
        this.pickerApiLoaded = true;
    }

    createPicker(deferred) {
        this.authInstance.isSignedIn.listen(this.handleAuthChange.bind(this));

        if (this.pickerApiLoaded && this.oauthToken) {
            const picker = new this.$window.google.picker.PickerBuilder()
                .addView(
                    new this.$window.google.picker.DocsView(this.$window.google.picker.ViewId.SHARED_WITH_ME)  // Add shared files
                        .setIncludeFolders(true)
                        .setMimeTypes('application/pdf,image/png,image/jpeg,image/jpg,image/gif')  // Same file types
                )
                .setOAuthToken(this.oauthToken)
                .setDeveloperKey(this.developerKey)
                .setCallback((data) => this.pickerCallback(deferred, data))
                .build();
            picker.setVisible(true);
            this.$window.parent.postMessage({ action: 'picker_loaded' }, '*');
        } else {
            this.$log.error('Picker API not loaded or OAuth token missing');
            deferred.reject('Picker API not loaded or OAuth token missing');
        }
    }

    // Fetch file content as a Blob
    async fetchFileContent(fileId) {
        const response = await fetch(
            `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,  // Use alt=media to download the file
            {
                headers: {
                    Authorization: `Bearer ${this.oauthToken}`,  // Use the OAuth token for authorization
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Error downloading file content: ${response.statusText}`);
        }

        // Return the response as a Blob
        return await response.blob();
    }

    // Create a File object using the Blob and metadata
    async createFileObject(file) {
        try {
            // Fetch the file content as a Blob
            const fileContent = await this.fetchFileContent(file.id);

            // Create a new File object
            const fileObject = new File(
                [fileContent],  // Blob content
                file.name,      // File name
                {
                    type: file.mimeType || 'application/octet-stream',  // MIME type, default to binary
                    lastModified: new Date(file.modifiedTime).getTime()  // Last modified time in milliseconds
                }
            );

            return fileObject;  // Return the constructed File object
        } catch (error) {
            this.$log.error('Error creating File object:', error);
            throw error;
        }
    }

    async pickerCallback(deferred, data) {
        if (data[this.$window.google.picker.Response.ACTION] === this.$window.google.picker.Action.PICKED) {
            const file = data[this.$window.google.picker.Response.DOCUMENTS][0];


            try {
                // Create the File object
                const fileObject = await this.createFileObject(file);

                // Post the file object to the parent window
                this.$window.parent.postMessage({ action: 'picked', documents: [fileObject] }, '*');

                // Resolve the deferred promise with the File object
                var importResult = ImportImageDialogController.show(
                    this.$q,
                    this.$mdDialog,
                    [],
                    true,
                    true,
                    undefined,
                    undefined,
                    MimeTypes.ImageSupport,
                    fileObject,
                );

                this.importResultCallback(importResult);
            } catch (error) {
                this.$log.error('Error creating File object:', error);
                deferred.reject('Error creating File object');
            }
        } else if (data[this.$window.google.picker.Response.ACTION] === this.$window.google.picker.Action.CANCEL) {
            deferred.reject('Picker canceled');
        } else {
            this.$log.error('Unknown picker action:', data);
            deferred.reject('Unknown picker action');
        }
    }

    handleMessage(event) {
        if (event.origin !== window.location.origin) {
            return;
        }

        if (event.data) {
            if (event.data.action === 'picked') {
                const file = event.data.documents[0];
            }
        }
    }

    handleAuthChange(isSignedIn) {
        if (!isSignedIn) {
            this.oauthToken = null;
        } else {
            this.authInstance.currentUser.get().reloadAuthResponse().then((response) => {
                this.oauthToken = response.access_token;
            }).catch((error) => {
                this.$log.error('Error refreshing OAuth token:', error);
            });
        }
    }
}
