/* eslint-disable angular/di */

import 'angular';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { config } from './index.config';
import { routerConfig } from './index.route';
import { runBlock } from './index.run';
import { polyfill } from './index.polyfill';
import { themeConfig } from './index.theme';
import { Environment } from './services/config/environment';
import { Constants } from './services/config/constants';
import { react2angular } from 'react2angular';
// import jsyaml from 'js-yaml';

import AssignmentSheetDirective from './components/assignment-sheet/assignment-sheet.directive';
import AssignmentToolbarDirective from './components/assignment-toolbar/assignment-toolbar.directive';
import CkAutofocusDirective from './components/ck-autofocus/ck-autofocus.directive';
import AssignmentQuestionDirective from './components/assignment-question/assignment-question.directive';
import SavingIndicatorDirective from './components/saving-indicator/saving-indicator.directive';
import FileInputDirective from './components/file-input/file-input.directive';
import NgReadyDirective from './components/ng-ready/ng-ready.directive';
import ImageCropperDirective from './components/image-cropper/image-cropper.directive';
import GradeInput from './components/grade-input/grade-input.directive';
import CompilerDirective from './components/compiler/compiler.directive';
import HelpRequestIndicatorDirective from './components/help-request-indicator/help-request-indicator.directive';
import HelpInboxDirective from './components/help-inbox/help-inbox.directive';
import AudioCaptureDirective from './components/audio-capture-directive/audio-capture.directive';
import AiAssistantCaptureDirective from './components/ai-assistant-capture-directive/ai-assistant-capture.directive';
import CloneDirective from './components/clone/clone.directive';
import AssignmentToolbarItemDirective from './components/assignment-toolbar/assignment-toolbar-item.directive';
import NgEnterDirective from './components/ng-enter/ng-enter.directive';
import CkSelectDirective from './components/ck-select/ck-select.directive';
import CkIndicatorCircleDirective from './components/ck-indicator-circle/ck-indicator-circle.directive';
import CkDraggableDirective from './components/ck-draggable/ck-draggable.directive';
import AssignmentThumbnailDirective from './components/assignment-thumbnail/assignment-thumbnail.directive';

import HelpCenterComponent from './components/help-center/help-center.component';
import ContributorsTabComponent from './components/contributors-tab/contributors-tab.component';
import ChatMessageComponent from './components/chat-message/chat-message.component';
import ToolbarColorMenuComponent from './components/assignment-toolbar/toolbar-color-menu.component';
import ToolbarEmojiMenuComponent from './components/assignment-toolbar/toolbar-emoji-menu.component';
import ToolbarFontFamilyMenuComponent from './components/assignment-toolbar/toolbar-font-family-menu.component';
import CkSwitchComponent from './components/ck-switch/ck-switch.component';
import FeedbackIndicatorComponent from './components/feedback-indicator/feedback-indicator.component';
import ArticleAutocompleteComponent from './components/article-autocomplete/article-autocomplete.component';
import SelectPurchasePeriodController from './components/select-purchase-period/select-purchase-period.component';
import SelectAdminEmailsController from './components/select-admin-emails/select-admin-emails.component';
import SignUpComponentController from './components/sign-up-component/sign-up.component';
import AccountLoginComponentController from './components/account-login-component/account-login-component';
import OrderObjectByFilter from './components/order-object/order-object.filter';

import AuthService from './services/auth/auth.service';
import ArchiveService from './services/archive/archive.service';
import BootstrapService from './services/bootstrap/bootstrap.service';
import FirebaseService from './services/firebase/firebase.service';
import StaticService from './services/static/static.service';
import StaticContentService from './services/static/static-content.service';
import AssignmentService from './services/assignment/assignment.service';
import AssignmentTrackingService from './services/assignment-tracking/assignment-tracking.service';
import FocusManagerService from './services/focus-manager/focus-manager.service.js';
import ToolbarService from './services/toolbar/toolbar.service.js';
import HelpRequestService from './services/help-request/help-request.service';
import MixpanelService from './services/mixpanel/mixpanel.service';
import RosterService from './services/roster/roster.service';
import GoogleFormService from './services/google-forms/google-form.service';
import GooglePickerService from './services/google-drive-file-picker/google-drive-file-picker.service';
import NotificationService from './services/notification/notification.service';
import StorageService from './services/storage/storage.service';
import UserService from './services/user/user.service';
import CacheService from './services/cache/cache.service';
import ClassCodeService from './services/class-code/class-code.service';
import HttpService from './services/http/http.service';
import AssignmentWorkService from './services/assignment-work/assignment-work.service';
import BreadcrumbService from './services/breadcrumb-service/breadcrumb.service';
import MediaService from './services/media/media.service';
import ImageEditService from './services/image-edit/image-edit.service';
import MessageService from './services/message/message.service';
import StickerService from './services/sticker/sticker.service';
import ExportService from './services/export/export.service';
import ContractService from './services/contract/contract.service';
import CampaignService from './services/campaign/campaign.service';
import StudentCacheService from './services/student-cache/student-cache.service';
import InviteCodeService from './services/invite-code/invite-code.service';
import CsvService from './services/csv/csv.service';
import ManipulativeElementService from './services/manipulative-element/manipulative-element.service';
import SubscriptionService from './services/subscription/subscription.service';
import StripeService from './services/stripe/stripe.service';
import ABTestService from './services/ab-test/ab-test-service';
import StudentAssignmentService from './services/student-assignment/student-assignment.service';
import GoogleClientService from './services/google/google-client.service';
import GoogleClassroomService from './services/google/google-classroom.service';
import DelightedService from './services/delighted/delighted.service';
import LogRocketService from './services/log-rocket/log-rocket.service';
import GradeExportService from './services/grade-export/grade-export.service';
import CleverService from './services/clever/clever.service';
import AnswerExportService from './services/answer-export/answer-export.service';
import SlideBackgroundService from './services/slide-background/slide-background.service';
import GoogleAnalyticsService from './services/google-analytics/google-analytics.service';
import FeedbackService from './services/feedback/feedback.service';
import OrganizationService from './services/organization/organization.service';
import IPStackService from './services/ip-stack/ip-stack.service';
import GooglePlacesService from './services/google-places/google-places.service';
import OrderService from './services/order/order.service';
import HelpArticleService from './services/help-article/help-article.service';
import PlatformHeaderService from './services/platform/platform-header.service';
import NotificationShardKeyFactory from './model/domain/notification-shard-key-factory.js';
import DatadogRumService from './services/datadog-rum/datadog-rum.service';
import DomObservabilityService from './services/dom-observability/dom-observability.service';
import FreeTrialService from './services/free-trial/free-trial.service';
import ContributorHistoryService from './services/contributor-history/contributor-history.service';
import PendoService from './services/pendo/pendo.service';
import AnalyticsMetaService from './services/analytics/analytics-meta.service';
import AnalyticsService from './services/analytics/analytics.service';
import BulkUpdateService from './services/bulk-update/bulk-update.service';
import IncentiveService from './services/incentive/incentive.service';
import SharedWorksService from './services/shared-works/shared-works.service';
import CoTeacherService from './services/co-teacher/co-teacher.service';
import GradebookService from './services/gradebook/gradebook.service';

import RootController from './controllers/root/root.controller';
import LoginController from './controllers/login/login.controller';
import ForgotPasswordController from './controllers/forgot-password/forgot-password.controller';
import SignupChoiceController from './controllers/signup-choice/signup-choice.controller';
import SignupNameController from './controllers/signup-name/signup-name.controller';
import ErrorController from './controllers/error/error.controller';
import PasswordResetController from './controllers/password-reset/password-reset.controller';
import AccountController from './controllers/account/account.controller';
import NavController from './controllers/nav/nav.controller';
import AssignmentsListController from './controllers/assignments-list/assignments-list.controller';
import RostersListController from './controllers/rosters-list/rosters-list.controller';
import UserProfileController from './controllers/user-profile/user-profile.controller';
import AssignmentEditController from './controllers/assignment-edit/assignment-edit.controller';
import AssignmentExportDialogController from './components/assignment-export-dialog/assignment-export-dialog.controller';
import PublicAssignmentController from './controllers/public-assignment/public-assignment.controller';
import PublicAssignmentQuestionController from './controllers/public-assignment-question/public-assignment-question.controller';
import PublicFolderController from './controllers/public-folder/public-folder.controller';
import AssignmentEditQuestionController from './controllers/assignment-edit-question/assignment-edit-question.controller';
import SessionWatchController from './controllers/session-watch/session-watch.controller';
import SessionWorkController from './controllers/session-work/session-work.controller';
import RosterEditController from './controllers/roster-edit/roster-edit.controller';
import ShareDialogController from './components/share-dialog/share-dialog.controller';
import SelectPeersDialogController from './components/select-peers-dialog/select-peers-dialog.controller';
import AssignmentTeacherFeedbackController from './controllers/assignment-teacher-feedback/assignment-teacher-feedback.controller';
import UserNotificationsListController from './controllers/user-notifications-list/user-notifications-list.controller';
import AdminController from './controllers/admin/admin.controller';
import ContractUsersListController from './controllers/contract-users-list/contract-users-list.controller.js';
import MetricListController from './controllers/metric-list/metric-list.controller';
import StudentAssignmentsListController from './controllers/student-assignments-list/student-assignments-list.controller';
import BaseEditUserController from './controllers/edit-user/base-edit-user.controller';
import ContractUserEditController from './controllers/edit-user/contract-user-edit.controller';
import NonContractUserEditController from './controllers/edit-user/non-contract-user-edit.controller';
import PaymentController from './controllers/payment/payment.controller';
import PaymentSuccessController from './controllers/payment-success/payment-success.controller';
import PublicAssignmentsListController from './controllers/public-assignments-list/public-assignments-list.controller';
import LoadingController from './controllers/loading/loading.controller';
import AccountHomeController from './controllers/account-home/account-home.controller';
import AnonStudentAssignmentWorkController from './controllers/anon-student-assignment-work/anon-student-assignment-work.controller';
import AnonStudentAssignmentOverviewController from './controllers/anon-student-assignment-overview/anon-student-assignment-overview.controller';
import AnonStudentAssignmentFeedbackController from './controllers/anon-student-assignment-feedback/anon-student-assignment-feedback.controller';
import StudentAccountAssignmentWorkController from './controllers/student-account-assignment-work/student-account-assignment-work.controller';
import StudentAccountAssignmentOverviewController from './controllers/student-account-assignment-overview/student-account-assignment-overview.controller';
import StudentAccountAssignmentFeedbackController from './controllers/student-account-assignment-feedback/student-account-assignment-feedback.controller';
import StudentOverviewController from './controllers/student-overview/student-overview.controller';
import SignupSchoolController from './controllers/signup-school/signup-school.controller';
import SessionQuestionController from './controllers/session-question/session-question.controller';
import AccountCleverController from './controllers/account-clever/account-clever.controller';
import AssignmentLibraryController from './controllers/assignment-library/assignment-library.controller';
import SessionController from './controllers/session/session.controller';
import OrderController from './controllers/order/order.controller';
import OrderDetailController from './controllers/order-detail/order-detail.controller';
import SubmitOrderController from './controllers/submit-order/submit-order.controller';
import AssignmentRostersController from './controllers/assignment-rosters/assignment-rosters.controller';
import HelpController from './controllers/help/help.controller';
import HelpCategoryController from './controllers/help-category/help-category.controller';
import HelpArticleController from './controllers/help-article/help-article.controller';
import HelpSectionController from './controllers/help-section/help-section.controller';
import HelpCoachesController from './controllers/help-coaches/help-coaches.controller';
import AdminInvitesTeachersDialogController from './components/admin-invites-teachers-dialog/admin-invites-teachers-dialog.controller';
import FreeTrialDialogController from './components/free-trial-dialog/free-trial-dialog.controller';
import CreateContractStudentsDialogController from './components/create-contract-students-dialog/create-contract-students-dialog.controller';
import AddProStudentsDialogController from './components/add-pro-students-dialog/add-pro-students-dialog.controller';
import SelectMdrOrgDialogController from './components/select-mdr-org-dialog/select-mdr-org-dialog.controller';
import ProTrialOverlayController from './components/pro-trial-overlay/pro-trial-overlay.controller';
import HiddenRostersListController from './controllers/hidden-rosters-list/hidden-rosters-list.controller';
import ProTrialConversionOverlayController from './components/pro-trial-conversion-overlay/pro-trial-overlay-conversion.controller';
import QuoteIframeDialogController from './components/quote-iframe-dialog/quote-iframe-dialog.controller';
import ReferralDialogController from './components/referral-dialog/referral-dialog.controller';
import ProTrialExtensionOverlayController
  from './components/pro-trial-extension-overlay/pro-trial-extension-conversion.controller';
import PreviewStudentAssignmentOverviewController
  from './controllers/preview-student-assignment-overview/preview-student-assignment-overview.controller';
import PreviewStudentAssignmentWorkController
  from './controllers/preview-student-assignment-work/preview-student-assignment-work.controller';
import ChooseAssignmentDialogController
  from './components/choose-assignment-dialog/choose-assignment-dialog.controller';
import IncentiveAssignmentLimitV1DialogController
  from './components/incentive-assignment-limit-v1-dialog/incentive-assignment-limit-v1-dialog.controller';
import SharedWorkController from './controllers/shared-work/shared-work.controller';
import SignUpOrLoginDialogController from './components/sign-up-or-login-dialog/sign-up-or-login-dialog.controller';
import AddNewStickerDialogController from './components/add-new-sticker-dialog/add-new-sticker-dialog.controller';
import InviteCoTeacherDialogController from './components/invite-co-teacher-dialog/invite-co-teacher-dialog.controller';
import GradeExportDialogController from './components/grade-export-dialog/grade-export-dialog.controller';


export function bootAngular() {
  angular.module('classkickApp', ['ngAnimate', 'ngCookies', 'ngSanitize', 'ngMessages', 'ngAria', 'ngResource', 'ui.router', 'ngMaterial', 'cfp.hotkeys', 'angular-clipboard', 'luegg.directives', 'ui.sortable'])
    .run(polyfill)

    .constant('moment', moment)
    .constant('firebase', firebase)
    .config(config)
    .config(routerConfig)
    .config(themeConfig)
    // hotkeys comes with a cheatsheet that can be toggled on and off with '?'. Disabling for now because we have few hotkeys.
    .config((hotkeysProvider) => {
      hotkeysProvider.includeCheatSheet = false;
    })

    .directive('assignmentSheet', AssignmentSheetDirective)
    .directive('assignmentToolbar', AssignmentToolbarDirective)
    .directive('assignmentToolbarItem', AssignmentToolbarItemDirective)
    .directive('ckAutofocus', CkAutofocusDirective)
    .directive('assignmentQuestion', AssignmentQuestionDirective)
    .directive('savingIndicator', SavingIndicatorDirective)
    .directive('fileInput', FileInputDirective)
    .directive('ngReady', NgReadyDirective)
    .directive('imageCropper', ImageCropperDirective)
    .directive('gradeInput', GradeInput)
    .directive('compile', CompilerDirective)
    .directive('helpRequestIndicator', HelpRequestIndicatorDirective)
    .directive('helpInbox', HelpInboxDirective)
    .directive('audioCapture', AudioCaptureDirective)
    .directive('aiAssistantCapture', AiAssistantCaptureDirective)
    .directive('clone', CloneDirective)
    .directive('ngEnter', NgEnterDirective)
    .directive('ckSelect', CkSelectDirective)
    .directive('ckIndicatorCircle', CkIndicatorCircleDirective)
    .directive('ckDraggable', CkDraggableDirective)
    .directive('assignmentThumbnail', AssignmentThumbnailDirective)

    .component('helpCenter', HelpCenterComponent)
    .component('contributorsTab', ContributorsTabComponent)
    .component('chatMessage', ChatMessageComponent)
    .component('toolbarColorMenu', ToolbarColorMenuComponent)
    .component('toolbarEmojiMenu', ToolbarEmojiMenuComponent)
    .component('toolbarFontFamilyMenu', ToolbarFontFamilyMenuComponent)
    .component('ckSwitch', CkSwitchComponent)
    .component('feedbackIndicator', FeedbackIndicatorComponent)
    .component('articleAutocomplete', ArticleAutocompleteComponent)
    .component('selectPurchasePeriod', SelectPurchasePeriodController)
    .component('selectAdminEmails', SelectAdminEmailsController)
    .component('signUpComponent', SignUpComponentController)
    .component('accountLoginComponent', AccountLoginComponentController)
    


    .filter('orderObjectBy', OrderObjectByFilter)

    .service('FirebaseService', FirebaseService)
    .service('ArchiveService', ArchiveService)
    .service('AuthService', AuthService)
    .service('BootstrapService', BootstrapService)
    .service('StaticService', StaticService)
    .service('StaticContentService', StaticContentService)
    .service('HttpService', HttpService)
    .service('AssignmentService', AssignmentService)
    .service('AssignmentTrackingService', AssignmentTrackingService)
    .service('FocusManagerService', FocusManagerService)
    .service('ToolbarService', ToolbarService)
    .service('HelpRequestService', HelpRequestService)
    .service('RosterService', RosterService)
    .service('GoogleFormService', GoogleFormService)
    .service('GooglePickerService', GooglePickerService)
    .service('NotificationService', NotificationService)
    .service('StorageService', StorageService)
    .service('UserService', UserService)
    .service('CacheService', CacheService)
    .service('ClassCodeService', ClassCodeService)
    .service('AssignmentWorkService', AssignmentWorkService)
    .service('BreadcrumbService', BreadcrumbService)
    .service('MediaService', MediaService)
    .service('ImageEditService', ImageEditService)
    .service('MessageService', MessageService)
    .service('StickerService', StickerService)
    .service('ExportService', ExportService)
    .service('ContractService', ContractService)
    .service('CampaignService', CampaignService)
    .service('StudentCacheService', StudentCacheService)
    .service('InviteCodeService', InviteCodeService)
    .service('CsvService', CsvService)
    .service('ManipulativeElementService', ManipulativeElementService)
    .service('SubscriptionService', SubscriptionService)
    .service('StripeService', StripeService)
    .service('ABTestService', ABTestService)
    .service('StudentAssignmentService', StudentAssignmentService)
    .service('GoogleClientService', GoogleClientService)
    .service('GoogleClassroomService', GoogleClassroomService)
    .service('DelightedService', DelightedService)
    .service('GradeExportService', GradeExportService)
    .service('CleverService', CleverService)
    .service('AnswerExportService', AnswerExportService)
    .service('SlideBackgroundService', SlideBackgroundService)
    .service('FeedbackService', FeedbackService)
    .service('OrganizationService', OrganizationService)
    .service('IPStackService', IPStackService)
    .service('GooglePlacesService', GooglePlacesService)
    .service('OrderService', OrderService)
    .service('HelpArticleService', HelpArticleService)
    .service('PlatformHeaderService', PlatformHeaderService)
    .service('NotificationShardKeyFactory', NotificationShardKeyFactory)
    .service('FreeTrialService', FreeTrialService)
    .service('PendoService', PendoService)
    .service('IncentiveService', IncentiveService)
    .service('SharedWorksService', SharedWorksService)
    .service('BulkUpdateService', BulkUpdateService)
    .service('CoTeacherService', CoTeacherService)
    .service('GradebookService', GradebookService)

    // Observability
    .service('DatadogRumService', DatadogRumService)
    .service('GoogleAnalyticsService', GoogleAnalyticsService)
    .service('LogRocketService', LogRocketService)
    .service('MixpanelService', MixpanelService)
    .service('DomObservabilityService', DomObservabilityService)
    .service('ContributorHistoryService', ContributorHistoryService)
    .service('AnalyticsMetaService', AnalyticsMetaService)
    .service('AnalyticsService', AnalyticsService)

    .controller('RootController', RootController)
    .controller('LoginController', LoginController)
    .controller('ForgotPasswordController', ForgotPasswordController)
    .controller('SignupChoiceController', SignupChoiceController)
    .controller('SignupNameController', SignupNameController)
    .controller('ErrorController', ErrorController)
    .controller('PasswordResetController', PasswordResetController)
    .controller('AccountController', AccountController)
    .controller('NavController', NavController)
    .controller('AssignmentsListController', AssignmentsListController)
    .controller('RostersListController', RostersListController)
    .controller('UserProfileController', UserProfileController)
    .controller('AssignmentEditController', AssignmentEditController)
    .controller('AssignmentExportDialogController', AssignmentExportDialogController)
    .controller('PublicAssignmentController', PublicAssignmentController)
    .controller('PublicAssignmentQuestionController', PublicAssignmentQuestionController)
    .controller('AssignmentEditQuestionController', AssignmentEditQuestionController)
    .controller('SessionWatchController', SessionWatchController)
    .controller('SessionWorkController', SessionWorkController)
    .controller('RosterEditController', RosterEditController)
    .controller('ShareDialogController', ShareDialogController)
    .controller('SelectPeersDialogController', SelectPeersDialogController)
    .controller('AssignmentTeacherFeedbackController', AssignmentTeacherFeedbackController)
    .controller('UserNotificationsListController', UserNotificationsListController)
    .controller('AdminController', AdminController)
    .controller('ContractUsersListController', ContractUsersListController)
    .controller('MetricListController', MetricListController)
    .controller('StudentAssignmentsListController', StudentAssignmentsListController)
    .controller('BaseEditUserController', BaseEditUserController)
    .controller('ContractUserEditController', ContractUserEditController)
    .controller('NonContractUserEditController', NonContractUserEditController)
    .controller('PaymentController', PaymentController)
    .controller('PaymentSuccessController', PaymentSuccessController)
    .controller('PublicAssignmentsListController', PublicAssignmentsListController)
    .controller('PublicFolderController', PublicFolderController)
    .controller('LoadingController', LoadingController)
    .controller('AccountHomeController', AccountHomeController)
    .controller('AnonStudentAssignmentWorkController', AnonStudentAssignmentWorkController)
    .controller('AnonStudentAssignmentOverviewController', AnonStudentAssignmentOverviewController)
    .controller('AnonStudentAssignmentFeedbackController', AnonStudentAssignmentFeedbackController)
    .controller('StudentAccountAssignmentWorkController', StudentAccountAssignmentWorkController)
    .controller('StudentAccountAssignmentOverviewController', StudentAccountAssignmentOverviewController)
    .controller('StudentAccountAssignmentFeedbackController', StudentAccountAssignmentFeedbackController)
    .controller('StudentOverviewController', StudentOverviewController)
    .controller('SignupSchoolController', SignupSchoolController)
    .controller('SessionQuestionController', SessionQuestionController)
    .controller('AccountCleverController', AccountCleverController)
    .controller('AssignmentLibraryController', AssignmentLibraryController)
    .controller('SessionController', SessionController)
    .controller('OrderController', OrderController)
    .controller('OrderDetailController', OrderDetailController)
    .controller('SubmitOrderController', SubmitOrderController)
    .controller('AssignmentRostersController', AssignmentRostersController)
    .controller('HelpController', HelpController)
    .controller('HelpCategoryController', HelpCategoryController)
    .controller('HelpArticleController', HelpArticleController)
    .controller('HelpSectionController', HelpSectionController)
    .controller('HelpCoachesController', HelpCoachesController)
    .controller('AdminInvitesTeachersDialogController', AdminInvitesTeachersDialogController)
    .controller('FreeTrialDialogController', FreeTrialDialogController)
    .controller('CreateContractStudentsDialogController', CreateContractStudentsDialogController)
    .controller('AddProStudentsDialogController', AddProStudentsDialogController)
    .controller('ContributorsTabController', ContributorsTabComponent.controller)
    .controller('SelectMdrOrgDialogController', SelectMdrOrgDialogController)
    .controller('ProTrialOverlayController', ProTrialOverlayController)
    .controller('HiddenRostersListController', HiddenRostersListController)
    .controller('ProTrialConversionOverlayController', ProTrialConversionOverlayController)
    .controller('QuoteIframeDialogController', QuoteIframeDialogController)
    .controller('ReferralDialogController', ReferralDialogController)
    .controller('ProTrialExtensionOverlayController', ProTrialExtensionOverlayController)
    .controller('PreviewStudentAssignmentOverviewController', PreviewStudentAssignmentOverviewController)
    .controller('PreviewStudentAssignmentWorkController', PreviewStudentAssignmentWorkController)
    .controller('ChooseAssignmentDialogController', ChooseAssignmentDialogController)
    .controller('IncentiveAssignmentLimitV1DialogController', IncentiveAssignmentLimitV1DialogController)
    .controller('SharedWorkController', SharedWorkController)
    .controller('SignUpOrLoginDialogController', SignUpOrLoginDialogController)
    .controller('AddNewStickerDialogController', AddNewStickerDialogController)
    .controller('InviteCoTeacherDialogController', InviteCoTeacherDialogController)
    .controller('GradeExportDialogController', GradeExportDialogController)
    .run(runBlock)
    ;

  Environment();
  Constants();
}
